import React from 'react';
import {
  ContactPageLayout,
  ContactPageContent
} from '../../redesign/molecules/contact';

const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

const JOIN_FLOW_URL = isProduction
  ? 'https://join.parsleyhealth.com/buy/complete-care/'
  : 'https://join.staging.parsleyhealth.com/buy/complete-care/';
const MY_PARSLEY_URL = isProduction
  ? 'https://my.parsleyhealth.com'
  : 'https://my.staging.parsleyhealth.com/';

const SCHEDULE_CHECK_IN_URL = `https://calendly.com/parsleyheath-mx/member-check-in?month=${new Date().getFullYear()}-${String(
  new Date().getMonth() + 1
).padStart(2, '0')}`;

const FreeConsultationCallPage: React.FC = () => {
  return (
    <ContactPageLayout pageTitle="Contact Us | Parsley Health" isLarge>
      <ContactPageContent
        joinFlowUrl={JOIN_FLOW_URL}
        myParsleyUrl={MY_PARSLEY_URL}
        scheduleCheckInUrl={SCHEDULE_CHECK_IN_URL}
        showDirectCtas={false}
      />
    </ContactPageLayout>
  );
};

export default FreeConsultationCallPage;
